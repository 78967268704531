import React, {Component} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery';

class Paangarh extends Component {
  render () {
    return (
      <>
       <div className="gtco-loader"></div>
        <div id="page">
           <Header title = "Paangarh">
                <h1>Paangarh</h1>
           </Header>
           <div class="gtco-section">
                <div class="gtco-container">
                    <div class="row animate-box">
                        <div class="col-md-12 text-center gtco-heading" data-animate-effect="fadeInLeft">
                            <h2>Brief</h2>
                            <p></p>
                        </div>
                    </div>
                    <div class="row animate-box">
                    <div class="col-md-12">
                        <div class="animate-box gtco-heading" data-animate-effect="fadeInLeft">
                          <p>
                            Bijaipur rehasat ka antim gaav, jo Amarpura se 12km duri par uttar disha mai sthit hai.Jile ka sabse bada prakratic jalashay hai, jo satat 300 barso se bhara hua hai.
                            Esa mana jata hai ki takriban 300 salo purve paangarh mai ek raja ka rajye tha .Uske rajye mai bhisan akal pada  tha, tab ek rishi mahatama ne kaha ki agar apka rajkumar and uski patni agar dono kile ke neeche bane hue madan mai alag rahe  tatha kisi se na mile to aapke rajye mai barish ho sakti hai.Eske ek saal baad hi barsaat hue aur itni jor se hue ki samast madan jaha rajkumar tatha uski patni rahate the pani se bhar gaya.Kai varso tak unka ghar pani mai hi duba raha.
                            log kahate he ki lagbhag 50 varso tak pani mai dube hue ghar se dhuaa nikalta tha iska matlab rajkumar and uski patni dono pani ke under bhi 50 varso tak jivit rahe.
                          </p>
                        </div>
                        <div class="feature-left animate-box gtco-heading" data-animate-effect="fadeInLeft">
                          <p>
                            Is kahani se prabhavit hokar 100 vars purve gangrar ka ek kinner(hijda,having no child) jab paangarh pahucha to usne talab ke paas jakar kaha ki, agar is jalashay mai rajkumar tatha uski patni ne sachmuch mai jal samadhi li ho to mai ek kinner hote hue bhi kisi bache ki maa ban jaauga .kuch hi deno baad uske ek bacha pada hua jiska naam usne "Jhunkavar" rakha.<br/>
                            Tabhi se us sthan ka naam jhunkavar ho gaya aur yahe ek pavan sthan ban gaya.
                            uski tarah kai santaanhin dampati yaha aate hai tatha palna bandhate hai aur unko santaan ki prapti hoti hai.
                            paangrh videshi prayatak ka mukhay aakrashan kendra hai.
                          </p>
                        </div>
                        <div class="feature-left animate-box gtco-heading" data-animate-effect="fadeInLeft">
                          <p>
                            Boating resort ,hotel's,camp fire aadi bhi uplabdha(available) hai
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <Gallery type = "paangarh"/>
            <Footer />
        </div>
      </>
    );
  }
}

export default Paangarh;
