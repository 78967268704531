import React, {Component} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery';

class ModiyaMahadev extends Component {
  render () {
    return (
      <>
       <div className="gtco-loader"></div>
        <div id="page">
           <Header title = "Modiya Mahadev">
                <h1>Modiya Mahadev</h1>
           </Header>
           <div class="gtco-section">
                <div class="gtco-container">
                    <div class="row animate-box">
                        <div class="col-md-12 text-center gtco-heading" data-animate-effect="fadeInLeft">
                            <h2>Brief</h2>
                            <p></p>
                        </div>
                    </div>
                    <div class="row animate-box">
                    <div class="col-md-12">
                          <div class="animate-box gtco-heading" data-animate-effect="fadeInLeft">
                            <p>Etihas prasid(famous) Aravali ki pahadiyo par Chittorgarh-Kanera (vaya-bassi,bijaipur) marg par sthit Gopalnagar gaav ke samip lagbhag ek hajar varse purana shiv manidir hai.Yahi par guru shree Syambaba ka aashram hai,prakratik susma se pripuran is anchal ke mukhya marg par ek vishal dawar bana hua hai.vishal dawar mai     parves(enter) kar lagbhag sava kilometer ka rasta tay karne ke paschaat aata hai 'Shyama aashram'.Yah aashram Chittorgarh se 48km tathaa Kanera se 25 km ki duri par sthit hai. Ek choti-si pahadi hai usi par Shivji ka mandir bana hua hai.Yahi par va rtmaan guruji "shree Shital Baba" ka aavas(home) bhi hai tathaa esi ke sammukh aadarniy guruji dawara mitti(soil) ke Dawadash ling sthatpit kiye gaye hai.Yahi ek akhand jyoti bhi hai,kuch aur bhi mandir vaha sthaapit kiye gaye hai.Esi pahadi ke
                neeche sameep hi pujya shree Syama baba ki samadhi sthit hai,unhone yaha jivit samadhi li thi.</p>
                          </div>
                          <div class="feature-left animate-box gtco-heading" data-animate-effect="fadeInLeft">
                            <p>Abhi kuch samay purv hi guruji shree Shital baba ne is samadhi ka jirnodawar(navnirman) karvaya.Is samadhi ke ek taraf guruji shree shyam baba ki dharam nistha shisya "Ramibai ki bhi samadhi hai.Guruji ne savyam hi samdhi dilvaai thi.Guruji ki samadhi ke dusri aur ek bavdi hai,iska pavitra jal(pani) samstha rogo tathaa kast ko dur karne vala hai.Bhaktgan badi sankhaya mai yaha ka jal shrda sahit botlo mai bhar kar le jate hai.Samadhi ke paas hi ek vishal 'Dhuna' tathaa Akhand jyoti hai jo hameshaa jalti rahati hai.Shiv mandir se samadhi tak ciment ka marg bana hua hai.Vaise bhi pravatiy shetra(bhu-bhag) ka sondarya lubhavana hota hi hai ,phir bhi  varsa ritu ke paschaat is vanachal ka sondraya varnatit(batane like) ho jata hai.Saat-aat(7-8) varse purva yah bhu-bhag ek dam sunsaan aur lagbhag ujaad sa thaa ki achanak pujya guruji shree <b>Shital baba</b> ka pdarparn(aana) yaha hua aur sara bhu-bhag(setra) guljar ho gaya,kaha to sandhaya hota hi is bhu-bhag mai siyaro and anye(dusre) vanye jantuo ka karkash aur dravani aavaje thi aur aaj din-rat bhajan-kirtan,shankh gadiyalo aur aartiyu ki prandayin-dhavaniya sunai deti hai.</p>
                          </div>
                          <div class="feature-left animate-box gtco-heading" data-animate-effect="fadeInLeft">
                            <p>Such hi hai-sant ke per(paav) jungle mai bhi pade to vaha bhi mangal ho jata hai,karuna ki murti Shittal baba ne ghor parisaram karke is lagbhag upexit sthan mai pran phuk diye hai,pure aashram ka kaya kalp kar diya hai,lakho rupaye vayy(kharcha) kar ke
                ise ek tirth ka rup de diya hai abhi pujya guruji ne ,pahadi par aashram tathaa Shiv mandir tak jane ke liye purani sidiya tudva kar unke sthaan par sundar,chodi tathaa aaram dayak sidiya banvayi hai.Vartmaan sansad mahoday shree Shreechand kriplani,purva vidhayak mahoday shree Surendre shih jadavat tathaa panchayat samiti,Chittorgarh ne apni nidhiyu se aashram me do(2) vishaal barmadu ka nirmaan karvaya,jinki vishaal chatu par vibhin dharmik karykaram aayojit tathaa sampadit hote hai tathaa bramade bhagat gano ke vishram hatu upyog mai aate hai pratayak(her) somvar ko tathaa ab lagbhag sabhi ravivaro ko pujya guruvar sant  shree Shital baba aashram par hi virajate hai.aashram mai pratidin tinu samay aarti hoti hai-pratah:6 baje ,madhayah:12 baje tathaa saayh:6 baje pratidin yaha par anek shradalu aate hai
                aur manovanchit phAL paate hai rogi savasthay leker jaate hai,putrahinu ki god hari hoti hai,devik,dehik,aur bhotik,kisi bhi prakar ka kast ho,jisne bhi shrada purvak
                guruji se tarun yachana ki,un sabhi ke kast dur hue hai.</p>
                          </div>
                          <div class="feature-left animate-box gtco-heading" data-animate-effect="fadeInLeft">
                            <p>Jo niskam se pujya guru ke aashram me padarat hai,unhe aanand rupi prasaad milta hai.Ashram ki aur se sabhi shradaluo ke liye yaha pratidin,chay(tea),bhojan aur thaharane ki vayvasthaa hai.Guru dawar se koi bhi nirasha aur khali haatha nahi jaat hai.Vaise to prati(her) somvar aasram mai bhajan sandhaya aur ratri jagran niyamit rup se hote hi hai parantu vishes rup se "Shivratri" aur "Guru purnima" ke paavan parvo par yaha shradaa,bhagati,aanand ka surmaya vatavarn sthaapit ho jata hai.In dono maha parvo par desh ke prashid kalakaro dawara bhajan sandhaye aayojit ki jaati hai.Bhagvan Shiv ka vishes abhishek kiya jaata hai.56 bhog chadaye jate hai tathaa hajaru bhagatu ko paavan bhoj karaya jata hai.Maha Shivratri par bhagvan Shiv ke mandir par tathaa Guru purnima ko pujya Guru dev ki samadhi par 1001 dip(dipak) jotiya arpit ki jati hai.In dip jyotiyu ki chataa nirali hi hoti hai.In dono parvo par hajaru shradaluo ,bhagatu ke liye bhojan tathaa thahrane ki vayvasthaa aashram ki aur se nishulk ki jaati hai sache man se samadhi ke sanmukh ki gayi pratayak kamana guruji kripa se avashya purn hoti hai.<br/>&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;Om Shanti ......Shanti.....Shanti       .</p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          <Gallery type = "modiya-mahadev"/>
          <Footer />
        </div>
      </>
    );
  }
}

export default ModiyaMahadev;
