import React, {Component} from 'react';

const currentYear = new Date().getFullYear();

class Footer extends Component {
  render () {
    return (
        <>
            <footer id="gtco-footer" role="contentinfo">
                <div className="gtco-container animate-box text-center">
                    <div className="row row-pb-md ">
                        <div className="col-md-4 col-sm-4 col-xs-6 ">
                            <ul className="gtco-footer-links">
                                <li><a href="/">Home</a></li>
                                <li><a href="paangarh">Paangarh</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-4 col-xs-6 ">
                            <ul className="gtco-footer-links">
                                <li><a href="about">About</a></li>
                                <li><a href="bijaipur">Bijaipur</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-6 ">
                            <ul className="gtco-footer-links">
                                <li><a href="modiya-mahadev">Modiya Mahadev</a></li>
                                <li><a href="contact-us">Contcat Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row copyright">
                        <div className="col-md-12">
                            <p>
                                <small className="block" style={{color: "#EFEFF1"}}>&copy; 2013-{currentYear} Amarpura. All Rights Reserved.</small>
                            </p>
                            {/* <p className="pull-right">
                                <ul className="gtco-social-icons pull-right">
                                    <li><a href="#"><i className="icon-twitter"></i></a></li>
                                    <li><a href="#"><i className="icon-facebook"></i></a></li>
                                    <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                    <li><a href="#"><i className="icon-dribbble"></i></a></li>
                                </ul>
                            </p> */}
                        </div>
                    </div>
                </div>
            </footer>
            <div className="gototop js-top">
                <a href="#" className="js-gotop"><i className="icon-arrow-up"></i></a>
            </div>
        </>
    )}
}

export default Footer;