import React, {Component} from 'react';
import { connect } from "react-redux";
import GalleryFactory from './GalleryFactory';

class Gallery extends Component {
  render () {
    const imagesData = this.props.galleryImages[this.props.type];
    
    return (
        	<GalleryFactory imagesData = {imagesData}/>
    )}
}

const mapStateToProps = state => {
    return {
        galleryImages: state.gallery
    }
}

export default connect(mapStateToProps)(Gallery);