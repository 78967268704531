import React, {Component} from 'react';
import { Helmet } from 'react-helmet'

class Header extends Component {
  render () {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let title = this.props.title && (this.props.title + " | ") || "";
    title = capitalizeFirstLetter(title);

    title += "Amarpura";

    return (
        <>
            <Helmet>
                <title>{ title }</title>
            </Helmet>
            <nav className="gtco-nav" role="navigation">
                <div className="gtco-container">
                    <div className="row">
                        <div className="col-xs-2">
                            <div id="gtco-logo"><a href="/">Amarpura.</a></div>
                        </div>
                        <div className="col-xs-10 text-right menu-1">
                            <ul>
                                <li className="active" ><a href="/">Home</a></li>
                                <li ><a href="about">About</a></li>
                                <li className="has-dropdown">
                                    <a href="javascript:;">Near By</a>
                                    <ul className="dropdown">
                                        <li><a href="modiya-mahadev">Modiya Mahadev</a></li>
                                        <li><a href="paangarh">Paangarh</a></li>
                                        <li><a href="bijaipur">Bijaipur</a></li>
                                    </ul>
                                </li>
                            <li ><a href="contact-us">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <header id="gtco-header" className="gtco-cover gtco-cover-sm" role="banner" style={{backgroundImage: `url(${'images/bg.jpg'})`}}>
                <div className="overlay"></div>
                <div className="gtco-container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 text-center">
                            <div className="display-t">
                                <div className="display-tc animate-box" data-animate-effect="fadeIn">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )}
}

export default Header;