import React, {Component} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'

class Home extends Component {
  render () {
    return (
      <>
       <div className="gtco-loader"></div>
        <div id="page">
           <Header>
                <h1>Dream Village</h1>
                <h2>Ek Visit to banta hai !</h2>
           </Header>
            
            <div id="gtco-subscribe">
                <div className="row animate-box">
                    <div className="col-md-8 col-md-offset-2 text-center gtco-heading">
                        <h2>Be the first to visit among your friends</h2>
                        <p></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      </>
    );
  }
}

export default Home;
