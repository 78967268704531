import React, {Component} from 'react';

class GalleryFactory extends Component {
  render () {
    const imagesData = this.props.imagesData;
    
    if (!imagesData || !Array.isArray(imagesData.images))
        return null;

    return (
        <>
        	<div id="gtco-team" class="gtco-section">
                <div class="gtco-container">
                    <div class="row animate-box">
                        <div class="col-md-8 col-md-offset-2 text-center gtco-heading">
                            <h2>Gallery</h2>
                        </div>
                    </div>
                    <div class="row row-pb-md">
                        {
                            imagesData.images.map( image => (
                                <div key={image.name} className="col-md-4 animate-box" data-animate-effect="fadeIn">
                                        <img src={imagesData.path + image.name} style={{width: "100%", height: "100%", padding: "0px", marginBottom: "10px" , border: "25px solid transparent", borderColor: "#F0F0F0"}} alt="village,green valley"/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )}
}

export default GalleryFactory;
