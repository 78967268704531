import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/home';
import About from './pages/about';
import Bijaipur from './pages/bijaipur';
import ModiyaMahadev from './pages/modiya-mahadev';
import Paangarh from './pages/paangarh';
import ContactUs from './pages/contact-us';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/about' component={About}></Route>
        <Route exact path='/bijaipur' component={Bijaipur}></Route>
        <Route exact path='/modiya-mahadev' component={ModiyaMahadev}></Route>
        <Route exact path='/paangarh' component={Paangarh}></Route>
        <Route exact path='/contact-us' component={ContactUs}></Route>
        {/* <Route path='/arts/:type' component={Arts}></Route> */}

        {/* Redirect all 404's to home */}
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;