import React, {Component} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                fname: "",
                lname: "",
                email: "",
                subject: "",
                message: ""
            },
            userMessage: ""
        };
    }

    encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    setSuccess = () => this.setState({userMessage: "<span className='text-success'>Success</span>"});
    setError = () => this.setState({userMessage: "<span className='text-danger'>Failed</span>"});

    handleSubmit = e => {
        fetch("/contact-us", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: this.encode({ "form-name": "form 1", ...this.state.form })
        })
        .then((data) => this.setSuccess())
        .catch(error => this.setError());

        e.preventDefault();
    };

    handleChange = e => {
        const form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({form});
    }

    render () {
        return (
        <>
        <div className="gtco-loader"></div>
            <div id="page">
            <Header title = "Contact Us">
                    <h1>Contact Us</h1>
            </Header>
            <div className="gtco-section" id="movehere">
                <div className="gtco-container">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 animate-box">
                            <h3>Get In Touch</h3>
                            <div className="form-header">
                            </div>
                            <form method='post' onSubmit={this.handleSubmit} netlify>
                                <div className="row form-group">
                                    <div className="col-md-6">
                                        <label for="fname">First Name</label>
                                        <input type="text" name='fname' required  id="fname" className="form-control" placeholder="Your firstname" onChange={this.handleChange}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label for="lname">Last Name</label>
                                        <input type="text" name='lname' required  id="lname" className="form-control" placeholder="Your lastname" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label for="email">Email</label>
                                        <input type="text" name='email' id="email" className="form-control" placeholder="Your email address" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label for="subject">Subject</label>
                                        <input type="text" name='subject' id="subject" className="form-control" placeholder="Your subject of this message" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label for="message">Message</label>
                                        <textarea name="message" id="message" cols="30" rows="10" className="form-control" placeholder="Write us something" onChange={this.handleChange}></textarea>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="submit" value="Send Message" className="btn btn-primary"/>
                                </div>
                                {
                                    this.state.userMessage &&
                                    <div dangerouslySetInnerHTML={{__html: this.state.userMessage}} />
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </div>
        </>
        );
    }
}

export default ContactUs;
