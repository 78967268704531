import React, {Component} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'

class Bijaipur extends Component {
  render () {
    return (
      <>
       <div className="gtco-loader"></div>
        <div id="page">
           <Header title = "Bijaipur">
                <h1>Bijaipur</h1>
           </Header>
           <div class="gtco-section">
                <div class="gtco-container">
                    <div class="row animate-box">
                        <div class="col-md-12 text-center gtco-heading" data-animate-effect="fadeInLeft">
                            <h2>Brief</h2>
                            <p></p>
                        </div>
                    </div>
                    <div class="row animate-box">
                        <div class="col-md-12">
                            <div class="animate-box gtco-heading" data-animate-effect="fadeInLeft">
                                <p>Bijaipur mevar rehasat ki ek ekai hai.Vartaman mai Rav Narendra shih iske raja hai.Is ravale ke adhin 60 gaav aate hai.In gaavo ke log aaj bhi inhe aapna raja mante hai tatha usi tarah samaan bhi karate hai.<br/>
                                Yaha par do(2) 3 sitara hotele hai <br/>
                                1.-Hotel Castel
                                2.-Hotel Pratap palace
                                Yaha par verse bhar videsi paryatak aate hai.paangarh bandh bhi isi ravle ke under aata hai.<br/>
                                Vaha par bhi ek(1) hotel ki sthapana ki gayi hai jiska sanchalan rav naredra shih dwara kiya jata hai.<br/>
                                Bijaipur mai "motidasji ki mandi" ka sanchalan 60 gaavo ke gramino dwara kiya jata hai.Isi tarah Narsing dware ka sanchalan bijaipur ke gramino dwara kiya jata hai.
                                    .</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      </>
    );
  }
}

export default Bijaipur;
