import React, {Component} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery';

class About extends Component {
  render () {
    return (
      <>
       <div classNameName="gtco-loader"></div>
        <div id="page">
           <Header title = "About">
                <h1>About</h1>
           </Header>
            
           <div id="gtco-services">
            <div className="gtco-container">
                <div className="row animate-box">
                    <div className="gtco-tabs">
                        <ul className="gtco-tab-nav">
                            <li className="active"><a href="#" data-tab="5"><span className="icon visible-xs"><i className="icon-box"></i></span><span className="hidden-xs">History</span></a></li>
                            <li><a href="#" data-tab="1"><span className="icon visible-xs"><i className="icon-command"></i></span><span className="hidden-xs">Dairy</span></a></li>
                            <li><a href="#" data-tab="2"><span className="icon visible-xs"><i className="icon-bar-graph"></i></span><span className="hidden-xs">Administrative System</span></a></li>
                            <li><a href="#" data-tab="3"><span className="icon visible-xs"><i className="icon-bag"></i></span><span className="hidden-xs">Culture</span></a></li>
                            <li><a href="#" data-tab="4"><span className="icon visible-xs"><i className="icon-box"></i></span><span className="hidden-xs">Govt. Workers</span></a></li>
                        </ul>
                        {/* Tabs */}
                        <div className="gtco-tab-content-wrap">
                        <div className="gtco-tab-content tab-content active" data-tab-content="5">
                                <div className="col-md-12">
                                    <h2>History</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                        <p>
                                        About 500 year's back A man named Amara put the base of Amarpura village.<br/>
                                        As Amarpura was a small unit of Chittorgarh(Chitragand morya ->Chitracut ->Chittorgarh) rehasat and considered  in the circumference of chittorgarh rehasat.<br/>
                                        At that time many types of taxes were taken by Bijaipur Jamindar who came under Chittorgarh rahasat.
                                        At present also people of 52 villages those comes under bijaipur rehasat consider Narendra shing rav as their local king.<br/>
                                        There are some old shilalekh's present like at Bus stand, near the tample.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gtco-tab-content tab-content" data-tab-content="1">
                                <div className="col-md-12">
                                    <h2>Dairy Farm</h2>
                                        <table className="table table-responsive">
                                            <tr>
                                                <td>
                                                    Lagbhag 15 sal(year) pahale log apna dugdha bachane bijaipur jate the.
                                                    san 1988 ke aaspaas Chittorgarh ki saras dairy se sampark judne ki vajah se dono time dugdha  bachane ki samsya khatam ho gayi .Logo ko isme fayda mahsus hone se unhone pashuu(janvar-gaay,bheis) ki dekharekha achi karne lage.Isi tarah dhire-dhire dugdha ka utpaadan bhi badne laga.2011 ke ant(aakhir) mai 'Rastriya krishi vikas yojana' ke antargat bulk kular(dugda thanda karne ki mashine) mai mili jiski capacity 2300 liter hai.Vartmaan mai 2000 liter daily dugdha ka sankalan hota hai,dugdha datao ko bhi dugdha ki uchit rate milti hai tatha log khush hai.
                                                    <h2 align="center"><font color="white">Milk rates(in Rs) according to Fat and CLR</font></h2>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table className="table table-responsive">
                                                        <tr>
                                                            <th>Fat &#160;&#160;&#160;&rArr;</th>
                                                            <th>3</th>
                                                            <th>3.4</th>
                                                            <th>3.8</th>
                                                            <th>4.2</th>
                                                            <th>4.6</th>
                                                            <th>5</th>
                                                            <th>5.4</th>
                                                            <th>5.8</th>
                                                            <th>6.2</th>
                                                            <th>6.6</th>
                                                            <th>7</th>
                                                            <th>7.4</th>
                                                            <th>7.8</th>
                                                            <th>8.2</th>
                                                            <th>8.6</th>
                                                            <th>9</th>
                                                            <th>9.4</th>
                                                            <th>9.7</th>
                                                            <th>10</th>
                                                        </tr>
                                                        <tr>
                                                            <th>degree/CLR&#160;&#160;&#160;&dArr;25</th>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>-</td>
                                                            <td>14.70</td>
                                                            <td>16.10</td>
                                                            <td>17.50</td>
                                                            <td>18.90</td>
                                                            <td>22.o4</td>
                                                            <td>24.49</td>
                                                            <td>26.07</td>
                                                            <td>28.00</td>
                                                            <td>29.97</td>
                                                            <td>32.76</td>
                                                            <td>34.77</td>
                                                            <td>36.46</td>
                                                            <td>38.52</td>
                                                            <td>39.74</td>
                                                            <td>41.47</td>
                                                            <td>43.60</td>
                                                        </tr>
                                                        <tr>
                                                            <th>26</th>
                                                            <td>10.5</td>
                                                            <td>11.9</td>
                                                            <td>13.3</td>
                                                            <td>14.7</td>
                                                            <td>17.48</td>
                                                            <td>19.75</td>
                                                            <td>21.33</td>
                                                            <td>23.20</td>
                                                            <td>25.11</td>
                                                            <td>27.72</td>
                                                            <td>29.68</td>
                                                            <td>31.38</td>
                                                            <td>33.38</td>
                                                            <td>35.42</td>
                                                            <td>37.50</td>
                                                            <td>39.60</td>
                                                            <td>41.36</td>
                                                            <td>42.68</td>
                                                            <td>44.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>27</th>
                                                            <td>10.5</td>
                                                            <td>12.92</td>
                                                            <td>15.1</td>
                                                            <td>17.45</td>
                                                            <td>18.40</td>
                                                            <td>20.24</td>
                                                            <td>22.68</td>
                                                            <td>24.59</td>
                                                            <td>26.54</td>
                                                            <td>28.25</td>
                                                            <td>30.24</td>
                                                            <td>32.26</td>
                                                            <td>34.32</td>
                                                            <td>36.08</td>
                                                            <td>37.84</td>
                                                            <td>39.60</td>
                                                            <td>41.36</td>
                                                            <td>42.68</td>
                                                            <td>44.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>28</th>
                                                            <td>15.3</td>
                                                            <td>16.2</td>
                                                            <td>16.85</td>
                                                            <td>17.69</td>
                                                            <td>19.5</td>
                                                            <td>21.4</td>
                                                            <td>23.11</td>
                                                            <td>25.06</td>
                                                            <td>27.03</td>
                                                            <td>29.04</td>
                                                            <td>30.80</td>
                                                            <td>32.56</td>
                                                            <td>34.32</td>
                                                            <td>36.08</td>
                                                            <td>37.84</td>
                                                            <td>39.60</td>
                                                            <td>41.36</td>
                                                            <td>42.68</td>
                                                            <td>44.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>29</th>
                                                            <td>15.55</td>
                                                            <td>14.42</td>
                                                            <td>16.26</td>
                                                            <td>18.14</td>
                                                            <td>19.87</td>
                                                            <td>21.80</td>
                                                            <td>23.76</td>
                                                            <td>25.52</td>
                                                            <td>27.80</td>
                                                            <td>29.04</td>
                                                            <td>30.80</td>
                                                            <td>32.56</td>
                                                            <td>34.32</td>
                                                            <td>36.08</td>
                                                            <td>37.84</td>
                                                            <td>39.60</td>
                                                            <td>41.36</td>
                                                            <td>42.68</td>
                                                            <td>44.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>30</th>
                                                            <td>12.96</td>
                                                            <td>14.69</td>
                                                            <td>16.57</td>
                                                            <td>18.48</td>
                                                            <td>20.24</td>
                                                            <td>22.00</td>
                                                            <td>23.76</td>
                                                            <td>25.52</td>
                                                            <td>27.28</td>
                                                            <td>29.04</td>
                                                            <td>30.80</td>
                                                            <td>32.56</td>
                                                            <td>34.32</td>
                                                            <td>36.08</td>
                                                            <td>37.84</td>
                                                            <td>39.60</td>
                                                            <td>41.36</td>
                                                            <td>43.12</td>
                                                            <td>44.00</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <h2 align="center"><font color="white">Milk rates(in Rs) of some previous years</font></h2>
                                                    <table className="table table-responsive">
                                                        <tr>
                                                            <th>Year</th>
                                                            <th>2007</th>
                                                            <th>2008</th>
                                                            <th>2009</th>
                                                            <th>2010</th>
                                                            <th>1011</th>
                                                            <th>2012</th>
                                                            <th>2013</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Cow</th>
                                                            <td>9.50</td>
                                                            <td>10-15</td>
                                                            <td>11-13</td>
                                                            <td>15-17</td>
                                                            <td>17-19</td>
                                                            <td>15.5-18</td>
                                                            <td>15-18</td>
                                                        </tr>
                                                        <tr>
                                                            <th>buffalo</th>
                                                            <td>21</td>
                                                            <td>22</td>
                                                            <td>18-27</td>
                                                            <td>20-30</td>
                                                            <td>24-36</td>
                                                            <td>24-36</td>
                                                            <td>25.5-40</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                </div>
                            </div>
                            <div className="gtco-tab-content tab-content" data-tab-content="2">
                                <div className="col-md-12">
                                    <h2>Administrative System</h2>
                                        <div className="row">
                                            <div className="col-md-12">
                                            <p>
                                            <ul className="list-group">
                                                <li className="list-group-item disabled">Amarpura ek graam panchayat mukhayalay hai jisme 17 gaav aate hai.Graam panchayat ki population takriban 3,100 hai.
                                            Amarpura panchayat ke antargat -</li>
                                                <li className="list-group-item">2  madhayamic(secondary) school</li>
                                                <li className="list-group-item">5  middle school</li>
                                                <li className="list-group-item">7  primary school</li>
                                                <li className="list-group-item">7  primary school</li>
                                                <li className="list-group-item">3  up svasthaye kendra</li>
                                                <li className="list-group-item">1  police choki (ravat ka talab)</li>
                                                <li className="list-group-item">1  van vibhag choki (palcha)</li>
                                                <li className="list-group-item">3   dam (Modiya mahadev,  Bhadkiya, Gunar)</li>
                                                <li className="list-group-item">1  Sub station (palcha)</li>
                                                <li className="list-group-item">Road damer lagbhag 75%</li>
                                            <li className="list-group-item">Sabhi gaavo ko prayapt bijli mil rahi hai</li>
                                            </ul>
                                            </p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="gtco-tab-content tab-content" data-tab-content="3">
                                <div className="col-md-12">
                                    <h2>Culture</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>
                                                Yaha par typical(vishiShT) village Culture hai.<br/>
                                                Mahilae -> gagra , lugda aur<br/>
                                                Purush  -> dhoti , kurta , pagadi.<br/>
                                                Nojavan -> jince , toujer  ie parmparic aur aadhunikata kaa mishran.<br/>
                                                <br/>
                                                Naata prathaa, Bahu vivah, Balvivah, Martiyubhoj, Choti-Moti samsyao ka nivaran panchparmeshawar dhawara kiya jata hai.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gtco-tab-content tab-content" data-tab-content="4">
                                <div className="col-md-12">
                                    <h2>Govt. Workers</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p>
                                                <ol className="list-group">
                                                    <li className="list-group-item disabled">Vartamaan(present) mai nimnlikhit karmachari hai -</li>
                                                    <li className="list-group-item">1.  Bhagvanlal ji Dhaker (retired)</li>
                                                    <li className="list-group-item">2.  Bholiram ji Dhaker (HM) (Bageria)</li>
                                                    <li className="list-group-item">3.  Maganlal ji Dhaker (HM) (Bassiguda)</li>
                                                    <li className="list-group-item">4.  Kanhaya lal ji Dhaker (HM) (Manakpura)</li>
                                                    <li className="list-group-item">5.  Nand kishor Dhaker (ups) (kochava)</li>
                                                </ol>
                                                <ol className="list-group">
                                                    <li className="list-group-item disabled">Vidyarthi mitra -</li>
                                                    <li className="list-group-item">1.  Jagdish chandra Vaishnav</li>
                                                    <li className="list-group-item">2.  Ratan lal Dhaker Dhaker</li>
                                                    <li className="list-group-item">3.  Bagdiram Rajapat</li>
                                                </ol>
                                                <ol className="list-group">
                                                    <li className="list-group-item disabled">Other -</li>
                                                    <li className="list-group-item">1.  Hitesh ji Sharma (LDC) (Amarpura)</li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Gallery type = "about"/>
        <Footer />
        </div>
      </>
    );
  }
}

export default About;
